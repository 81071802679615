<template>
  <section>
    <vue-confirm-dialog />
    <b-card title="Leads PESA">
      <b-row>
        <b-col
          cols="12"
        >
          <b-card-body>
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Filtrar por Interesse"
                  label-for="filtro_interesse"
                >
                  <v-select
                    id="filtro_interesse"
                    v-model="interesse"
                    label="nome"
                    :options="interesseLista"
                    placeholder="Status"
                    @input="escolheuStatus"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="3"
              >
                <b-form-group
                  label="Filtrar por Status"
                  label-for="filtro_status"
                >
                  <v-select
                    id="filtro_status"
                    v-model="status"
                    label="nome"
                    :options="listaStatus"
                    placeholder="Status"
                    @input="escolheuStatus"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="$can('admin')"
                md="3"
              >
                <b-form-group
                  label="Filtro Empresas"
                  label-for="filto"
                >
                  <v-select
                    id="filto"
                    v-model="selected"
                    label="nome"
                    :options="select"
                    placeholder="Empresa"
                    @input="buscaEmpresa"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <data-table
              :data="data"
              :columns="columns"
              @on-table-props-changed="reloadTable"
            >
              <tbody
                slot="body"
                slot-scope="{
                  // eslint-disable-next-line vue/no-template-shadow
                  data }"
              >
                <tr
                  v-for="item in data"
                  :key="item.id"
                >
                  <td
                    v-for="column in columns"
                    :key="column.name"
                  >
                    <data-table-cell
                      :value="item"
                      :name="column.name"
                      :meta="column.meta"
                      :comp="column.component"
                      :classes="column.classes"
                    />
                    <slot v-if="column.label === 'Ações'">
                      <b-button
                        v-b-modal.popup-lead
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        size="sm"
                        class="btn-icon rounded-circle"
                        @click="editDados(item)"
                      >
                        <feather-icon icon="EyeIcon" />
                      </b-button>
                    </slot>
                    <slot v-if="column.label === 'Status'">
                      <b-badge
                        v-if="item.statusnew"
                        :variant="StatusNew(item).cor"
                      >
                        <div v-if="item.statusnew">
                          {{ StatusNew(item).nome }}
                        </div>
                      </b-badge>
                      <b-badge
                        v-else
                        :variant="NomeStatus(item).cor"
                      >
                        <div>
                          {{ NomeStatus(item).msg }}
                        </div>
                      </b-badge>
                    </slot>
                    <slot v-if="column.label === 'Data'">
                      {{ item.created_at | moment("DD/MM HH:mm") }}
                    </slot>
                    <slot v-if="column.label === 'Interesse'">
                      {{ item.produtosinteresse }}
                    </slot>
                  </td>
                </tr>
              </tbody>
            </data-table>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
    <!--  POP UP -->
    <b-modal
      v-if="mostraPopUp"
      id="popup-lead"
      ok-only
      centered
      size="lg"
      title="Informações do Lead"
    >
      <b-row>
        <b-col>
          <b-row>
            <b-col md="6">
              <h2>
                Lead - {{ dados.nomecontato }}
              </h2>
            </b-col>
            <b-col
              md="4"
            >
              <b-form-group>
                <v-select
                  id="catalogaStatus"
                  v-model="statusLeadCategoriaConfirma"
                  label="nome"
                  :options="statusLeadCategoria"
                  placeholder="Esse Lead Converteu?"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="2"
              style="margin-top: 5px;"
            >
              <b-button
                size="sm"
                variant="outline-primary"
                @click="confirmaStatus()"
              >
                Confirmar
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card-body>
                <dl class="row">
                  <dt class="col-sm-3">
                    Nome:
                  </dt>
                  <dd class="col-sm-9">
                    {{ dados.nomecontato }}
                  </dd>
                </dl>

                <dl
                  v-if="dados.telefonecontato"
                  class="row"
                >
                  <dt class="col-sm-3">
                    Telefone:
                  </dt>
                  <div
                    class="col-sm-9"
                    @click="abreWhatsApp(dados.telefonecontato)"
                  >
                    {{ dados.telefonecontato }}
                    <a href="#" />
                    <div>
                      <img
                        width="15"
                        height="15"
                        :src="require('@/assets/images/ico/whats.png')"
                      >
                    </div>
                  </div>
                </dl>
                <dl
                  v-if="dados.emailcontato"
                  class="row"
                >
                  <dt class="col-sm-3">
                    E-mail:
                  </dt>
                  <dd class="col-sm-9">
                    {{ dados.emailcontato }}
                  </dd>
                </dl>
                <dl
                  v-if="dados.observacaolead"
                  class="row"
                >
                  <dt class="col-sm-3">
                    Obs:
                  </dt>
                  <dd class="col-sm-9">
                    {{ dados.observacaolead }}
                  </dd>
                </dl>
                <dl
                  v-if="dados.produtosinteresse"
                  class="row"
                >
                  <dt class="col-sm-3">
                    Produto de Interesse:
                  </dt>
                  <dd class="col-sm-9">
                    {{ dados.produtosinteresse }}
                  </dd>
                </dl>
                <dl
                  v-if="dados.utm_medium"
                  class="row"
                >
                  <dt class="col-sm-3">
                    UTM Med:
                  </dt>
                  <dd class="col-sm-9">
                    {{ dados.utm_medium }}
                  </dd>
                </dl>
                <dl
                  v-if="dados.utm_term"
                  class="row"
                >
                  <dt class="col-sm-3">
                    UTM Term:
                  </dt>
                  <dd class="col-sm-9">
                    {{ dados.utm_term }}
                  </dd>
                </dl>
                <dl
                  v-if="dados.melhorhorarioctt"
                  class="row"
                >
                  <dt class="col-sm-3">
                    Horario:
                  </dt>
                  <dd class="col-sm-9">
                    {{ dados.melhorhorarioctt }}
                  </dd>
                </dl>
                <dl
                  v-if="dados.utm_source"
                  class="row"
                >
                  <dt class="col-sm-3">
                    UTM Orig:
                  </dt>
                  <dd class="col-sm-9">
                    {{ dados.utm_source }}

                  </dd>
                </dl>
                <dl
                  v-if="dados.utm_campaign"
                  class="row"
                >
                  <dt class="col-sm-3">
                    UTM Camp:
                  </dt>
                  <dd class="col-sm-9">
                    {{ dados.utm_campaign }}
                  </dd>
                </dl>
                <dl
                  v-if="dados.utm_content"
                  class="row"
                >
                  <dt class="col-sm-3">
                    UTM Cont:
                  </dt>
                  <dd class="col-sm-9">
                    {{ dados.utm_content }}
                  </dd>
                </dl>
              </b-card-body>

            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
    <!--------------------- POPUP RELATORIO --------------------->
    <b-modal
      id="popup-confirmacao"
      title="Confirme o Novo Status do Lead"
      ok-only
      hide-footer
    >
      <b-card-text>
        <span>Escreva o relatório do atendimento com esse Lead</span>
      </b-card-text>
      <b-form-textarea
        id="textarea-rows"
        v-model="relatorioLead"
        placeholder="Relatório do atendimento"
        rows="8"
        class="mb-2"
      />
      <hr>
      <b-button
        variant="primary"
        block
        @click="salvarConversao()"
      >
        Salvar
      </b-button>
    </b-modal>
  </section>
</template>
<script>
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { VBToggle } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  filters: {
    corrigirTelefone(telefone) {
      if (telefone.contain('+55')) {
        return 'tem'
      }
      return 'não tem'
    },
  },
  mixins: [heightTransition],

  data() {
    return {
      id: null,
      mostraPopUp: false,
      opStatus: [
        { id: 1, text: true },
        { id: 0, text: false },
      ],
      url: 'admin/leads',
      data: {},
      select: [],
      status: '',
      listaStatus: [],
      interesse: '',
      interesseLista: [],
      statusLead: '',
      statusLeadCategoria: '',
      statusLeadCategoriaConfirma: '',
      relatorioLead: '',
      selected: '',
      dados: '',
      dadositem: {},
      tableProps: {
        search: '',
        length: 30,
        column: 'id',
        dir: 'desc',
      },
      empresa: null,
      option: [
        { id: 1, title: 'Sem Opções' },
      ],
      opcoesTipo: [
        { value: 1, text: 'Cliente' },
        { value: 2, text: 'Fornecedor' },
      ],
      columns: [
        {
          label: '#',
          name: 'id',
          orderable: true,
        },
        {
          label: 'Empresa',
          name: 'empresa.nome',
          orderable: true,
        },
        {
          label: 'Nome Contato',
          name: 'nomecontato',
          orderable: true,
        },
        {
          label: 'Telefone',
          name: 'telefonecontato',
          orderable: true,
        },
        {
          label: 'Interesse',
          orderable: true,
        },
        {
          label: 'Data',
          orderable: true,
        },
        {
          label: 'Status',
          orderable: true,
        },
        {
          label: 'Ações',
          filterable: false,
        },
      ],
    }
  },
  created() {
    const TodasEmpresas = []
    TodasEmpresas.push({
      id: null,
      nome: 'TODAS AS EMPRESAS',
    })
    this.$http.get('admin/empresas').then(resp => {
      resp.data.data.forEach(empresas => {
        TodasEmpresas.push({
          id: empresas.id,
          nome: empresas.nome,
        })
      })
      this.select = TodasEmpresas
    })
    this.BuscarDados(this.url)
    this.statusLeads()
    this.buscaStatus()
  },
  methods: {
    escolheuStatus() {
      this.BuscarDados()
    },
    async buscaStatus() {
      const todoStatus = [{ id: null, nome: 'Todos os status' }]
      const interesseLista = [{ id: null, nome: 'Todos os Interesses' }]
      await this.$http.get('admin/lista_status').then(resp => {
        resp.data.forEach(stat => { todoStatus.push({ id: stat.id, nome: stat.nome }) })
      })
      await this.$http.get('admin/lista_pinteresse').then(resp => {
        resp.data.forEach(stat => { interesseLista.push({ id: stat.nome, nome: stat.nome }) })
      })
      this.listaStatus = todoStatus
      this.interesseLista = interesseLista
    },
    confirmaStatus() {
      if (this.statusLeadCategoriaConfirma === '' || this.statusLeadCategoriaConfirma === null) {
        this.Notificacao('danger', 'Selecione o Status do Lead')
      } else {
        this.$bvModal.show('popup-confirmacao')
      }
    },
    // eslint-disable-next-line no-unused-vars
    abreWhatsApp(telefone) {
      // const tel = telefone.replace(/[^0-9]/g, '')
    },
    StatusNew(item) {
      if (item.statusnew.id === 1) {
        return { cor: 'light-primary', nome: item.statusnew.nome }
      }
      if (item.statusnew.id === 2) {
        return { cor: 'light-danger', nome: item.statusnew.nome }
      }
      if (item.statusnew.id === 3) {
        return { cor: 'light-success', nome: item.statusnew.nome }
      } return 'teste'
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'ALERTA',
        variant: cor,
        solid: true,
      })
    },
    async salvarConversao() {
      if (this.relatorioLead.length < 10) {
        this.Notificacao('danger', 'Preencha o campo de relatorio de atendimento')
        return
      }
      const obj = {
        id: this.dados.id,
        status_id: this.statusLeadCategoriaConfirma.id,
        observacao: this.relatorioLead,
      }
      await this.$http.post(`admin/kanban/attkanban/${this.dados.id}`, obj).then(resp => {
        if (resp.data.response === true) {
          this.Notificacao('success', resp.data.mensagem)
        }
        if (resp.data.response === false) {
          this.Notificacao('danger', resp.data.mensagem)
        }
        this.relatorioLead = ''
        this.$bvModal.hide('popup-confirmacao')
        this.$bvModal.hide('popup-lead')
        this.mostraPopUp = false
        this.reloadTable()
      })
    },
    statusLeads() {
      this.$http.get('admin/status').then(resp => {
        this.statusLeadCategoria = resp.data.data
      })
    },
    SalvarStatus(dados) {
      this.teste = dados
      const obj = {
        id: this.teste.id,
        status: 1,
      }
      this.$http.put(`admin/leads/${this.dados.id}`, obj).then(resp => {
        this.data = resp.data
        this.mostraPopUp = false
        this.reloadTable()
      })
    },
    buscaEmpresa() {
      this.BuscarDados(this.url)
    },
    editDados(item) {
      localStorage.setItem('lead', JSON.stringify(item))
      this.dados = JSON.parse(localStorage.getItem('lead'))
      this.mostraPopUp = true
    },
    NomeStatus(item) {
      if (item.status === 2) {
        return { cor: 'light-danger', msg: 'Inativo' }
      }
      if (item.status === 1) {
        return { cor: 'light-success', msg: 'Confirmado' }
      }
      if (item.status === 0) {
        return { cor: 'light-warning', msg: 'Pendente' }
      }
      return 'Sem Status'
    },
    BuscarDados(url = this.url, options = this.tableProps) {
      const opcoes = options
      opcoes.empresa = this.selected?.id
      if (this.status) { opcoes.status = this.status?.id }
      if (this.interesse) { opcoes.interesse = this.interesse?.id }
      opcoes.dir = 'desc'
      this.$http.get(url, { params: opcoes }).then(resp => {
        this.data = resp.data
      })
    },
    reloadTable(tableProps) {
      this.BuscarDados(this.url, tableProps)
    },
  },
}
</script>

<style>
.titulo-lead {
  font-size: 34px;
  margin-top: 20px;
}

.modal .modal-header {
  background-color: #fec91a;
  border-bottom: none;
}

.marcar-status {
  font-weight: 800;
}

th, td {
  padding: 0.72rem 10px !important;
}
</style>
